import { FC, ReactNode } from "react";
import {
  Box,
  Image,
  Flex,
  type FlexProps,
  Text,
  Button,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

type Props = FlexProps & {};

export const NotFoundContent: FC<Props> = ({ ...flexProps }) => {
  const router = useRouter();

  return (
    <>
      <Flex w="full" position="relative" overflow="hidden">
        <Flex
          mx="auto"
          gap="40px"
          flexDirection="column"
          px={{ base: "16px", lg: "24px", xl: "40px" }}
          w={{ base: "100%", xl: "1366px" }}
          h={{ base: "800px", xl: "800px" }}
          position="relative"
          {...flexProps}
        >
          <Box
            position="absolute"
            zIndex={1}
            left={0}
            top={0}
            bottom={0}
            w="full"
          >
            <Image
              src="/images/decor.png"
              alt="Amplify Vaults"
              objectFit="cover"
              w="full"
              h="full"
            />
          </Box>
          <Flex
            flexDirection="column"
            height="full"
            width="full"
            justifyContent="center"
            alignItems="center"
            position="relative"
            zIndex={2}
          >
            <Flex flexDirection="column" alignItems="center" gap="28px">
              <Flex flexDirection="column">
                <Text textAlign="center" size={{ base: "5xl", xl: "7xl" }}>
                  404
                </Text>
                <Text textAlign="center" size={{ base: "4xl", xl: "5xl" }}>
                  Page not found
                </Text>
              </Flex>
              <Button
                colorScheme="white"
                variant="solid"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                onClick={() => router.push("/")}
              >
                <Image
                  src="/icons/left/arrow/dark.svg"
                  alt="Go to homepage"
                  width="24px"
                  height="24px"
                />
                Go to homepage
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
